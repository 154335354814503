<template>
  <div class="content-container">
    <PageTitle :title="$t('EXTRA_GAMES.TITLE')" class="page-title" />
    <h1 class="page-section-title section-title">
      {{ $t("CREATE_EXTRA_GAME.NEW") }}
    </h1>
    <BackButton
      :text="$t('BACK_OVERVIEW')"
      :route-name="ROUTE_NAMES_CMS.EXTRAS"
    />
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>
        <!-- Title -->
        <InputField
          :field-title="$t('EXTRA_GAMES.TITLE')"
          :field-name="'extraName'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('EXTRA_GAMES.NEW_TITLE')"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="''"
          class="form-element"
        />

        <!-- Description --> <!--
        <RichTextField
          :field-title="$t('EXTRA_GAMES.DESCRIPTION')"
          :field-name="'description'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('EXTRA_GAMES.NEW_DESCRIPTION')"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="''"
          class="form-element"
        />  -->

        <RichTextField :field-title="$t('EXTRA_GAMES.DESCRIPTION')"
                       :input-callback="descriptionInput"
                       :error-text="richTextError"
                       :placeholder="$t('EXTRA_GAMES.NEW_DESCRIPTION')"
        />

        <!-- Partner Link -->
        <InputField
          :field-title="$t('EXTRA_GAMES.PARTNER_LINK')"
          :field-name="'extraLink'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('EXTRA_GAMES.EXAMPLE_LINK')"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="urlErrorText"
          class="form-element"
        />

        <div class="cover-photo-section">
          <h3>{{ $t("CREATE_PRIZE.COVER_PHOTO") }}</h3>
          <BasicButton
            v-if="!imagePreview"
            id="uploadPhoto"
            :button-text="$t('DIAS.FILE_CHOOSE')"
            :button-class="'base-button'"
            @click="createExtraImage"
          />

          <FilePreview
            v-if="imagePreview"
            id="coverPhoto-Image"
            :file="imagePreview"
            :remove-button-clicked="removeAttachment"
            :style-preset="'big'"
          />
        </div>

        <!-- Bits Awarded -->
        <div class="form-element-bits">
          <InputField
            :field-title="$t('EXTRA_GAMES.BITS_AWARDED')"
            :field-name="'bitsAwarded'"
            :rules="'required'"
            :type="'number'"
            :field-width="'27%'"
            :placeholder="0"
            :cy-selector="'name-input'"
            :input-icon-error="require('../../../assets/icons/icn_error.svg')"
            :api-error-text="''"
            class="form-element"
          />
        </div>

        <TagSelector
          ref="tagSelector"
          :field-title="$t('EXTRA_GAMES.TAGS')"
          :field-name="'tags'"
          :rules="'required'"
          :options="tagData"
          :update-parent="updateTags"
          :callback="updateTags"
          :error-text="tagSelectorError"
        />
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t("SAVE") }}
        </button>
      </template>
    </FormWrapper>

    <BasicModal
      v-if="isBasicVersionDisplayed"
      :button-right-disabled="!fileData"
    >
      <template #content>
        <file-dropbox
          :allowed-mime-types="allowedMimes"
          :style-preset="'small'"
          :callback="uploadFile"
        >
        </file-dropbox>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import ModalController, {
  MODAL_NAMES
} from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import router from '@/router'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import FileDropbox from '../../../components/elements/FileDropbox.vue'
import BasicButton from '../../../components/elements/basic/BasicButton.vue'
import FilePreview from '@/components/elements/FilePreview'
import TagSelector from '@/components/yo-form/TagSelector'
import nl from '@/utils/language/nl.json'
import { GET_TAGS, CREATE_EXTRA_GAME } from '@/store/modules/cms/actions'

export default {
  name: 'CreateExtraGame',
  components: {
    PageTitle,
    BackButton,
    InputField,
    FormWrapper,
    TagSelector,
    BasicModal,
    BasicButton,
    RichTextField,
    FileDropbox,
    FilePreview,
  },
  setup() {
    const store = useStore()
    const imagePreview = ref(null)
    const fileData = ref(null)
    const currentFormData = ref({})
    const allowedMimes = computed(() => ['image/png', 'image/jpeg', 'image/webp'])
    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(
      () => activeModal.value === MODAL_NAMES.CREATE_ATTACHMENT
    )

    const tagData = computed(() => store.getters.getTags)
    const selectedTags = ref([])
    const tagSelectorError = ref('')

    const descriptionValue = ref('')
    const richTextError = ref('')
    const urlErrorText = ref('')

    getTags()
    function getTags() {
      return store.dispatch(GET_TAGS, {})
    }

    function updateTags(selected) {
      selectedTags.value = selected
    }

    function validateData(data) {
      data.description = descriptionValue.value
      if (!data.description || data.description === '') {
        richTextError.value = nl.CREATE_EXTRA_GAME.NO_DESCRIPTION_GIVEN
        return false
      }
      data.mediaFile = fileData.value
      if (!fileData.value || fileData.value === '') {
        return false
      }
      data.tags = selectedTags.value
      if (!selectedTags.value || selectedTags.value === '' || selectedTags.value.length === 0) {
        tagSelectorError.value = nl.CREATE_EXTRA_GAME.NO_TAGS_GIVEN
        return false
      }
      // URL validity
      if (!/https?:\/\/?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g.test(data.extraLink)) {
        urlErrorText.value = 'De ingevulde link is niet geldig. Zorg ervoor dat het begint met \'https://\''
        return false
      }
      urlErrorText.value = ''
      data.name = data.extraName
      data.link = data.extraLink
      // console.log(data)
      store.dispatch(CREATE_EXTRA_GAME, data).then((result) => {
        console.log(result)
        if (result) {
          router.push({ name: ROUTE_NAMES_CMS.EXTRAS })
        }
      })
    }

    function descriptionInput(value) {
      descriptionValue.value = value
    }

    function createExtraImage() {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.UPLOAD_FILE,
        uploadAttachment,
        null,
        null,
        MODAL_NAMES.CREATE_PRIZE_IMAGE
      )
    }

    function uploadAttachment() {
      imageLoad()
      modalController.value.resetActiveModal()
    }

    function imageLoad() {
      if (fileData.value) {
        imagePreview.value = fileData.value
      }
    }

    function uploadFile(data) {
      fileData.value = data[0]
    }

    function updatedForm(data) {
      currentFormData.value = data
    }

    function removeAttachment() {
      fileData.value = null
      imagePreview.value = null
    }

    return {
      ROUTE_NAMES_CMS,
      isBasicVersionDisplayed,
      tagData,
      updateTags,
      validateData,
      allowedMimes,
      imagePreview,
      fileData,
      createExtraImage,
      uploadFile,
      removeAttachment,
      updatedForm,
      descriptionInput,
      richTextError,
      tagSelectorError,
      urlErrorText,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.cover-photo-section {
  margin-bottom: rem(24);
  button {
    background-color: white;
  }
  .file-image-preview.big {
    width: rem(350);
    height: rem(240);
  }

  h3 {
    color: var(--blue_dark);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.1875rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }
}

.form-element-bits {
  margin-bottom: rem(24);
}
</style>
